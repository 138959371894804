<template lang="pug">
  .pagination-widget
    select-items-per-page(:pagination="pagination" @change="changeItemsPerPageCount")
    pagination(
      :page="pagination.page"
      :size="pagination.size"
      :count="pagination.count"
      @click:prev="paginationChange"
      @click:next="paginationChange"
    )
</template>

<script>
import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'PaginationWidget',

  mixins: [paginationMixin],

  props: {
    pagination: Object,
    loading: Boolean
  },

  methods: {
    change() {
      this.$emit('change')
    },

    async paginationChange(page) {
      if (this.loading) return
      await this.$router.replace({query: {...this.$route.query, page: page}})
      this.change()
    },

    async changeItemsPerPageCount(payload) {
      await this.updatePageSize(payload)
      this.change()
    }
  },

  components: {
    pagination: () => import('@/components/global/pagination/Pagination.vue'),
    selectItemsPerPage: () => import('@/components/widget/SelectItemsPerPage.vue')
  }
}
</script>

<style lang="scss" scoped>
.pagination-widget {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
